body {
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
}

.etusivu {
    font-family: 'PT Sans Narrow', sans-serif;
}

.firstRow {
    text-align: center;
    padding: 20px;
    color: #002E2D;
    height: auto;
}

.firstText,
.secondText {
    font-size: 150%;
}

.firstText p {
    padding-top: 250px;
    padding-left: 250px;
}

.etusivuKuva {
    border: 3px solid #FE9A62;
    height: 800px;
    padding: 30px;
    margin: 30px;
}

.secondRow {
    height: auto;
    color: #002E2D;
    text-align: center;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
}

.secondImage {
    background-color: #00000010;
    padding: 130px 20px;
}

.tokaRiviKuva {
    width: 100%;
    border: 10px solid #FFF;
}

.secondText {
    background-color: #002E2D95;
}

.secondText p {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 30%;
    color: #FFF;
}

.thirdRow {
    height: auto;
    text-align: center;
    background-color: #B0560020;
    padding: 40px;
}

.thirdImages {
    border: 20px solid #FFF;
    width: 80%;
}

@media screen and (max-width: 1600px) {
    .firstText p {
        padding-left: 150px;
    }
}

@media screen and (max-width: 1400px) {
    .firstText p {
        padding-left: 50px;
    }
    .secondText p {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30%;
    }
    .thirdImages {
        border: 10px solid #FFF;
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    .firstText p {
        padding-top: 175px;
        padding-left: 10px;
        font-size: 100%;
    }
    .etusivuKuva {
        height: 600px;
        padding: 15px;
        margin: 20px;
    }
}

@media screen and (max-width: 1100px) {
    .thirdRow {
        padding: 10px;
    }
}

@media screen and (max-width: 992px) {
    .firstText {
        padding: 20px;
        height: auto;
    }
    .firstText p {
        padding: 0 40px;
        font-size: 120%;
    }
    .etusivuKuva {
        text-align: center;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .secondImage {
        background-color: #002E2D95;
        padding: 80px 20px 20px 20px;
    }
    .secondText p {
        padding-top: 20px;
        padding-bottom: 80px;
        font-size: 120%;
    }
}

@media screen and (max-width: 768px) {
    .secondRow {
        height: auto;
    }
    .secondImage {
        height: auto;
        margin: 0;
        background-color: #002E2D95;
    }
    .col-md-4 {
        padding: 5px;
    }
}

@media screen and (max-width: 500px) {
    .etusivuKuva {
        height: auto;
        width: 95%;
    }
}