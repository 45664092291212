body {
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
}

.yhteydenotto {
    height: auto;
    padding-bottom: 50px;
    background-color: #002E2D10;
    text-align: center;
    font-family: 'PT Sans Narrow', sans-serif;
}

.otayhteytta {
    text-align: center;
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    padding: 40px;
    font-size: 250%;
    color: #002E2D90;
    width: 90%;
    border-bottom: 1px solid white;
    margin: auto;
}

.yhteystiedot {
    width: 350px;
    margin: auto;
    margin-top: 40px;
    border-bottom: 1px solid white;
    padding-bottom: 20px;
}

.yhteystiedot a,
.yhteystiedot p {
    color: #002E2D95;
    font-size: 150%;
    padding: 10px;
}

.yhteystiedot a:hover {
    color: #FE9A62;
}

.some {
    width: 140px;
    margin: 10px auto;
    text-align: center;
}

.ig,
.fb {
    width: 40px;
    margin: 20px 10px;
}

.instagram,
.facebook {
    float: left;
    width: 50%;
}

.some:after {
    content: "";
    display: table;
    clear: both;
}

.yhteydenottoLomake {
    text-align: center;
    max-width: 400px;
    margin: 20px auto;
}

.lomake {}

Form {
    border: 2px solid #FE9A6250;
    border-radius: 10px;
    padding: 30px;
    background-color: #002E2D05;
}

.lomake label {
    text-align: left;
    padding-left: 40px;
    padding-top: 10px;
}