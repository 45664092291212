@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
.palvelutTop {
    text-align: center;
    background: url('/src/images/dumbbells.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
}

.palvelutHeader {
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    color: #FFF;
    height: 475px;
    letter-spacing: 3px;
    padding-top: 200px;
}

.palvelutHeader h1 {
    padding: 30px;
    height: 100px;
    text-shadow: 1px 1px 10px #002E2D;
    border-bottom: 0.25px solid #FE9A62;
    font-size: 300%;
}

.palvelutKuvaus {
    height: auto;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    color: #000;
    letter-spacing: 1px;
    padding: 40px 0px 175px 0px;
}

.palvelutKuvaus p {
    width: 100%;
    margin: auto;
    background-color: #FFFFFF99;
    color: #000;
    text-shadow: 0px 0px 5px #FFF;
    font-size: 180%;
    padding-left: 10px;
    padding-right: 10px;
}

.palvelutKuvaus1 {
    padding-bottom: 10px;
    padding-top: 20px;
}

.palvelutKuvaus2 {
    padding-top: 10px;
    padding-bottom: 20px;
}

.erityis {
    height: auto;
    background-color: #FF850120;
    text-align: center;
    color: #002E2D99;
    padding: 220px 40px;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
}

.erityis h4 {
    width: 1000px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 165%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid #FE9A6280;
    text-shadow: 0px 0px 5px #FFF;
}

.erityis ul {
    padding: 0;
    padding-top: 20px;
}

.erityis li {
    font-size: 150%;
    padding: 20px;
    list-style-type: none;
    color: #002E2D90;
    transition: 0.4s;
}

.erityis li:hover {
    font-size: 170%;
    text-shadow: 0px 0px 5px #FFF;
}

.erityis .erityisAlin {
    border-bottom: none;
}

.benefits {
    height: auto;
    font-family: 'PT Sans Narrow', sans-serif;
    background-color: #002E2D80;
    padding: 130px 40px;
    text-align: center;
}

.benefitsBox {
    color: #002E2D90;
    background-color: #FFFFFF;
    padding: 80px 60px;
    width: 70%;
    margin: auto;
    border-radius: 10px 40px;
    text-align: left;
}

.benefits h4 {
    font-size: 175%;
    color: #002E2D;
    font-weight: lighter;
    font-family: 'PT Sans Narrow', sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-bottom: 2px solid #FE9A6280;
    width: 300px;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-left: 40px;
}

.benefits ul {
    list-style: none;
    padding-left: 0;
    list-style-position: outside;
}

.benefits ul li {
    font-size: 140%;
    padding: 10px 0px;
}

.benefits ul li::before {
    color: #FF8501;
    content: "\2022";
    padding-right: 20px;
    font-size: 135%;
}

.benefits p {
    font-size: 140%;
}

.ohjausKuva {
    background: url('/src/images/blackwhite.png');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    height: 90vh;
    border-top: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
}

.ravinto {
    background-color: #FF850120;
    color: #002E2D99;
    height: auto;
    padding-bottom: 10px;
}

.ravinto h4 {
    text-align: center;
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    padding-top: 40px;
    font-size: 300%;
    letter-spacing: 2px;
    text-shadow: 4px 4px 2px #FFF;
    color: #002E2D99;
}

.kuvaTeksti {
    width: 100%;
    padding: 20px;
}

.kuvaTeksti .kuvaTekstiDiv {
    width: 60%;
    float: left;
    padding: 80px 100px 0px 100px;
}

.ravinto .ruokakuva {
    width: 40%;
    float: left;
    padding: 30px;
    background-color: #FFF;
    border-radius: 10px;
}

.ravinto .ravintoTeksti {
    font-size: 170%;
    font-family: 'PT Sans Narrow', sans-serif;
}

.ravinto .olet {
    font-family: 'Great Vibes', cursive;
    font-size: 300%;
    color: #FF8501;
    text-align: center;
    padding-top: 20px;
    margin-left: -40px;
}

.kuvaTeksti:after {
    content: "";
    display: table;
    clear: both;
}

.kilpailu {
    background-color: #002E2D80;
    height: auto;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
}

.kilpailu h4 {
    font-family: 'Style Script', cursive;
    color: #FFF;
    letter-spacing: 2px;
    font-size: 350%;
    border-bottom: 1px solid #FE9A62;
    font-weight: lighter;
    text-align: center;
    padding-top: 60px;
    width: 335px;
    margin: auto;
    line-height: 80%;
}

.kilpailuDiv {
    width: 100%;
    padding: 30px 30px 40px 30px;
}

.kilpailuDiv img {
    width: 25%;
    float: left;
    padding: 10px;
    background-color: #FFF;
}

.kilpailuDiv p {
    width: 75%;
    float: left;
    font-family: 'PT Sans Narrow', sans-serif;
    color: #FFF;
    font-size: 175%;
    padding: 175px 40px 0 40px;
}

.nimiLinkki {
    font-family: 'Style Script', cursive;
    color: #FE9A62;
    font-size: 120%;
}

.nimiLinkki:hover {
    color: #002E2D;
}

.kilpailuDiv:after {
    content: "";
    display: table;
    clear: both;
}

.hinnat {
    text-align: center;
    background-color: #FF850120;
    color: #002E2D99;
    height: auto;
    font-family: 'PT Sans Narrow', sans-serif;
    padding: 100px;
}

.hinnat h4 {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 300%;
    text-transform: uppercase;
    padding-bottom: 30px;
}

.hinnat .hinnatKuvaus {
    font-size: 200%;
    padding-left: 20px;
    padding-right: 20px;
}

.hinnat .hinnatSis {
    color: #002E2D;
    border-bottom: 1px solid #FE9A6280;
    width: 325px;
    margin: auto;
    transition: 0.3s;
    font-size: 200%;
}

.hinnat p {
    padding: 5px;
    margin: 0;
}

.hinnatDiv {
    background-color: #FFFFFF90;
    margin: auto;
    width: 750px;
    padding: 30px;
    border-radius: 40px;
    margin-top: 20px;
    transition: 0.3s;
}

.hinnatDiv:hover .hinnatSis {
    width: 400px;
}

.hinnat ul {
    list-style: none;
    padding-top: 30px;
    color: #002E2D;
    padding-bottom: 20px;
}

.hinnat ul li {
    margin-left: -30px;
    font-size: 150%;
    padding: 10px;
}

.mayors {
    color: #002E2D99;
    text-transform: uppercase;
}

.mayors:hover {
    color: #FF8501;
}

.hinnat .alkaen {
    text-transform: uppercase;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    margin-left: 0px;
    font-size: 200%;
}

.linkkiDiv {
    margin-top: 60px;
    margin-bottom: 40px;
}

.yhteydenottoLinkki {
    color: #FFF;
    background-color: #FF850199;
    width: 200px;
    margin: auto;
    text-transform: uppercase;
    padding: 25px;
    font-size: 175%;
    border-radius: 20px;
    border: 1px solid #FFF;
}

.yhteydenottoLinkki:hover {
    background-color: #FFF;
    color: #FF850199;
    border: 1px solid #FF850199;
}

.hieronta {
    background-color: #FF850190;
    color: #FFF;
    padding: 60px 80px;
    font-family: 'PT Sans Narrow', sans-serif;
    border-top: 1px solid #FFF;
}

.hieronta h4 {
    font-family: 'PT Sans Narrow', sans-serif;
    text-transform: uppercase;
    font-size: 250%;
    border-bottom: 2px solid #FF8501;
    width: 275px;
}

.hieronta p {
    color: #FFF;
    font-size: 200%;
}

.hieronta p a {
    font-family: 'Style Script', cursive;
    font-size: 110%;
    color: #002E2D;
    text-shadow: 1px 1px 10px #FFF;
}

.hieronta p a:hover {
    color: #FFFFFF;
    text-shadow: 1px 1px 10px #002E2D;
}

@media screen and (max-width: 1500px) {
    .kuvaTeksti .kuvaTekstiDiv {
        width: 50%;
        padding: 50px 50px 0px 50px;
    }
    .ravinto .ravintoTeksti {
        font-size: 150%;
    }
    .ravinto .ruokakuva {
        width: 50%;
    }
    .kilpailuDiv img {
        width: 35%;
    }
    .kilpailuDiv p {
        width: 65%;
    }
}

@media screen and (max-width: 1200px) {
    .kuvaTeksti {
        padding: 20px 50px;
    }
    .kuvaTeksti .kuvaTekstiDiv {
        width: 100%;
    }
    .ravinto .ruokakuva {
        margin-left: 5%;
        width: 90%;
        margin-top: 40px;
    }
    .ravinto .olet {
        margin-left: 0px;
    }
    .kilpailuDiv p {
        padding: 125px 40px 0 40px;
    }
}

@media screen and (max-width: 1100px) {
    .erityis h4 {
        width: 90%;
        font-size: 150%;
    }
    .benefitsBox {
        width: 90%;
    }
    .kilpailuDiv p {
        padding: 100px 20px 0 20px;
        font-size: 150%;
    }
    .hinnat {
        padding: 100px 20px;
    }
    .hinnatDiv {
        width: 70%;
    }
}

@media screen and (max-width: 900px) {
    .palvelutKuvaus p {
        font-size: 150%;
    }
    .erityis {
        padding: 120px 40px;
    }
    .erityis h4 {
        font-size: 130%;
    }
    .erityis li {
        font-size: 125%;
        padding: 20px 5px;
    }
    .erityis li:hover {
        font-size: 125%;
    }
    .benefits {
        padding: 100px 0px;
    }
    .benefits ul li,
    .benefits p {
        font-size: 125%;
    }
    .benefitsBox {
        padding: 80px 30px;
    }
    .ohjausKuva {
        height: 60vh;
    }
    .kuvaTeksti .kuvaTekstiDiv {
        padding: 20px;
        text-align: center;
    }
    .ravinto .ruokakuva {
        padding: 20px;
    }
    .kilpailuDiv {
        padding: 30px 20px 40px 20px;
    }
    .kilpailuDiv img,
    .kilpailuDiv p {
        width: 50%;
    }
    .kilpailuDiv p {
        padding-right: 0;
    }
    .hinnatDiv {
        width: 80%;
    }
}

@media screen and (max-width: 750px) {
    .kilpailuDiv {
        padding: 60px 20px 40px 20px;
    }
    .kilpailuDiv img {
        width: 90%;
        margin-left: 5%;
    }
    .kilpailuDiv p {
        text-align: center;
        width: 100%;
        padding: 80px 10px;
    }
    .hinnat {
        padding: 80px 10px;
    }
    .hinnatDiv {
        width: 90%;
    }
    .hinnat .hinnatKuvaus,
    .hinnat .hinnatSis,
    .hinnat .alkaen {
        font-size: 150%;
    }
    .hinnat ul li {
        font-size: 125%;
    }
    .hinnat .hinnatSis {
        width: 80%;
    }
    .hinnatDiv:hover .hinnatSis {
        width: 90%;
    }
    .hieronta {
        padding: 60px 10%;
    }
    .hieronta h4 {
        font-family: 'PT Sans Narrow', sans-serif;
        text-transform: uppercase;
        font-size: 200%;
        border-bottom: 2px solid #FF8501;
        width: 225px;
    }
    .hieronta p {
        color: #FFF;
        font-size: 150%;
    }
    .hieronta p a {
        font-family: 'Style Script', cursive;
        font-size: 110%;
        color: #002E2D;
        text-shadow: 1px 1px 10px #FFF;
    }
}

@media screen and (max-width: 600px) {
    .palvelutHeader h1 {
        font-size: 250%;
    }
    .palvelutKuvaus p {
        font-size: 125%;
    }
    .erityis {
        padding: 80px 10px;
    }
    .benefits h4 {
        font-size: 160%;
        width: 90%;
        padding-left: 20px;
        margin: auto;
    }
    .ravinto h4 {
        font-size: 250%;
    }
    .kuvaTeksti {
        padding: 10px;
    }
    .ohjausKuva {
        height: 40vh;
    }
    .ravinto .ruokakuva {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
    .kilpailu h4 {
        font-size: 300%;
    }
    .kilpailuDiv {
        padding: 40px 20px 40px 20px;
    }
    .kilpailuDiv p {
        padding: 40px 10px;
    }
    .yhteydenottoLinkki {
        font-size: 150%;
    }
}