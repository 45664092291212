@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

body,
html {
    height: 100%;
}


/* https://codepen.io/codebucks27/pen/yLMvOPX*/

body {
    position: relative;
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
    width: 100%;
    border-top: 20px solid #FF8501;
}

.header {
    height: calc(90vh - 80px);
    border: 3px solid #FFF;
}

.header img {
    max-height: 90%;
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
}

#nav {
    border-top: 10px solid #FFF;
    border-bottom: 1px solid #FE9A62;
    height: 80px;
    height: calc(100% - 10px);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.navigation {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #FFF;
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    height: 50px;
}

.main-container {
    /*position: absolute;*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.nav-menu {
    display: flex;
    text-align: center;
    list-style: none;
}

.linkTo {
    text-decoration: none;
    color: #002E2D80;
    padding-right: 40px;
    padding: 0px 30px;
    /*border-right: 1px solid #002E2D80;*/
    font-size: 125%;
    height: 100%;
}

.links {
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 15px;
    float: none;
    line-height: 50px;
}

.links::after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.links:hover::after {
    width: 100%;
    background-color: #FE9A62;
}

.nav-icon {
    display: none;
}

#yhteydenotto {
    border-right: none;
}

.linkTo:hover {
    color: #FE9A62;
}

.kuvat {
    width: 50%;
}

@media screen and (max-width: 960px) {
    .header {
        height: calc(60% - 80px);
    }
    .header img {
        width: 72.5%;
    }
    #nav {
        border-top: 0;
        height: 50px;
        z-index: 1;
        /* position: sticky;*/
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #FE9A62;
        position: absolute;
        top: 50px;
        left: -110%;
        transition: all 0.5s ease;
        height: 500px;
        background-color: #FFF;
        padding-left: 0;
    }
    .nav-menu.active {
        left: 0px;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .links .active {
        color: #FE9A62;
        border: none;
        display: table;
    }
    .navigation {
        display: table;
        margin-right: 20px;
        border-bottom: 1px solid #FE9A62;
    }
    .linkTo {
        width: 100%;
        display: table;
        border-right: none;
    }
    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%, 60%);
                transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #FF8501;
    }
}
.footer {
    color: #FFF;
    background-color: #FF8501;
    height: auto;
    padding: 20px 20px 0 20px;
}

.footer .yhteys {
    height: auto;
    padding: 10px 10px 0 10px;
    border-bottom: 1px solid #002E2D;
}

.footer .yhteys h3 {
    font-family: 'Style Script', cursive;
    font-size: 200%;
    font-weight: lighter;
    margin-bottom: 5px;
}

.footer .yhteys .maili {
    color: #FFF;
    padding-left: 5px;
}

.footer .yhteys .insta,
.footer .yhteys .face {
    width: 40px;
    opacity: 0.8;
    margin: 20px 15px;
}

.footer .yhteys .face {
    margin-left: 0;
}

.igLogo,
.fbLogo {
    float: left;
}

.somet {
    margin: 0;
}

.somet:after {
    content: "";
    display: table;
    clear: both;
}

.footer .kaisa {
    text-align: center;
    height: auto;
    padding: 10px;
}

.footer .kaisa a {
    font-size: 95%;
    text-decoration: none;
    color: #FFF;
}

.footer .yhteys a:hover,
.footer .kaisa a:hover {
    color: #002E2D;
}
body {
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
}

.etusivu {
    font-family: 'PT Sans Narrow', sans-serif;
}

.firstRow {
    text-align: center;
    padding: 20px;
    color: #002E2D;
    height: auto;
}

.firstText,
.secondText {
    font-size: 150%;
}

.firstText p {
    padding-top: 250px;
    padding-left: 250px;
}

.etusivuKuva {
    border: 3px solid #FE9A62;
    height: 800px;
    padding: 30px;
    margin: 30px;
}

.secondRow {
    height: auto;
    color: #002E2D;
    text-align: center;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
}

.secondImage {
    background-color: #00000010;
    padding: 130px 20px;
}

.tokaRiviKuva {
    width: 100%;
    border: 10px solid #FFF;
}

.secondText {
    background-color: #002E2D95;
}

.secondText p {
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 30%;
    color: #FFF;
}

.thirdRow {
    height: auto;
    text-align: center;
    background-color: #B0560020;
    padding: 40px;
}

.thirdImages {
    border: 20px solid #FFF;
    width: 80%;
}

@media screen and (max-width: 1600px) {
    .firstText p {
        padding-left: 150px;
    }
}

@media screen and (max-width: 1400px) {
    .firstText p {
        padding-left: 50px;
    }
    .secondText p {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30%;
    }
    .thirdImages {
        border: 10px solid #FFF;
        width: 90%;
    }
}

@media screen and (max-width: 1200px) {
    .firstText p {
        padding-top: 175px;
        padding-left: 10px;
        font-size: 100%;
    }
    .etusivuKuva {
        height: 600px;
        padding: 15px;
        margin: 20px;
    }
}

@media screen and (max-width: 1100px) {
    .thirdRow {
        padding: 10px;
    }
}

@media screen and (max-width: 992px) {
    .firstText {
        padding: 20px;
        height: auto;
    }
    .firstText p {
        padding: 0 40px;
        font-size: 120%;
    }
    .etusivuKuva {
        text-align: center;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .secondImage {
        background-color: #002E2D95;
        padding: 80px 20px 20px 20px;
    }
    .secondText p {
        padding-top: 20px;
        padding-bottom: 80px;
        font-size: 120%;
    }
}

@media screen and (max-width: 768px) {
    .secondRow {
        height: auto;
    }
    .secondImage {
        height: auto;
        margin: 0;
        background-color: #002E2D95;
    }
    .col-md-4 {
        padding: 5px;
    }
}

@media screen and (max-width: 500px) {
    .etusivuKuva {
        height: auto;
        width: 95%;
    }
}
body {
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
}

.galleria {
    padding: 0px;
    padding-bottom: 20px;
    width: 100%;
}

.galleria h2 {
    font-family: 'PT Sans Narrow', sans-serif;
    color: #FFF;
    background-color: #002E2D90;
    margin: 0;
    padding: 40px;
    font-size: 225%;
    font-weight: lighter;
}

.galleria h2 a {
    font-family: 'Style Script', cursive;
    color: #FE9A62;
    font-size: 125%;
    transition: 0.4s;
}

.galleria h2 a:hover {
    color: #FFF;
    text-shadow: 2px 2px 5px #FE9A62;
    padding: 5px;
}

.galleria h3 {
    text-align: center;
    border-top: 1px solid #002E2D90;
    color: #002E2D90;
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    padding-top: 20px;
    margin-top: 0;
    font-size: 200%;
    letter-spacing: 1px;
}

@media screen and (max-width: 900px) {
    .galleria h2 {
        text-align: center;
        font-size: 200%;
    }
    .galleria h3 {
        font-size: 175%;
    }
}
    body {
        font-family: 'PT Sans Narrow', sans-serif;
        font-family: 'Yanone Kaffeesatz', sans-serif;
        font-family: 'Open Sans', sans-serif;
        color: #FFF;
        color: #FE9A62;
        color: #FF8501;
        color: #B05600;
        color: #002E2D;
        color: #000;
    }
    
    p {
        font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
        color: #002E2D90;
    }
    
    .mika {
        width: auto;
        margin: 0;
        padding: 0;
        font-family: 'PT Sans Narrow', sans-serif;
        font-size: 125%;
    }
    
    .topRow {
        padding: 40px;
    }
    
    .topRow h1 {
        text-align: center;
        font-family: 'Style Script', cursive;
        font-size: 400%;
        color: #002E2D;
        letter-spacing: 3px;
    }
    
    .sloganit {
        font-family: 'Great Vibes', cursive;
        text-align: center;
        color: #002E2D90;
        font-size: 200%;
    }
    
    .aboutMika {
        background-color: #002E2D10;
        height: auto;
        padding: 40px;
    }
    
    .aboutMikaKuva {
        text-align: center;
    }
    
    .mikanKuva {
        height: 850px;
        border: 20px solid #FFF;
        margin: auto;
    }
    
    .kuvaus p {
        color: #002E2D95;
        padding-top: 200px;
        padding-right: 100px;
        font-size: 140%;
    }
    
    .aboutMika2 {
        background-color: #002E2D20;
        height: auto;
        padding: 100px 40px;
        border-top: 1px solid #FFF;
    }
    
    .kuvaus2 {
        font-size: 110%;
    }
    
    .kuvaus2 p {
        padding: 100px 10px;
        color: #002E2D;
    }
    
    .kuvaus2kuva {
        text-align: center;
    }
    
    .linkki {
        color: #B0560090;
        font-size: 100%;
        text-transform: uppercase;
    }
    
    .linkki:hover {
        color: #FF850190;
        font-size: 102%;
    }
    
    .koira {
        height: 500px;
        margin: auto;
        border: 10px solid #FFF;
    }
    
    .slogan {
        background-color: #FFF;
        height: auto;
        text-align: center;
        padding: 50px;
    }
    
    .treenataan {
        border: solid #002E2D50;
        border-style: double;
        width: 600px;
        margin: auto;
        margin-bottom: 30px;
        padding: 30px;
    }
    
    .tausta {
        background-color: #002E2D35;
        height: auto;
        padding-top: 40px;
        padding-bottom: 10px;
    }
    
    .tausta h4 {
        height: 45px;
        font-size: 125%;
        margin-left: 45%;
        padding-top: 200px;
        padding-bottom: 40px;
        text-transform: uppercase;
        color: #FFF;
        letter-spacing: 2px;
        border-bottom: 2px solid #FE9A62;
        width: 500px;
        padding-left: 10px;
    }
    
    .taustaKuva {
        text-align: center;
    }
    
    .mika1982,
    .mika1994 {
        width: 325px;
        padding: 10px;
        background-color: #FFF;
        margin: 10px 60px 5px 60px;
    }
    
    .mika1982 {
        margin-top: -250px;
    }
    
    .taustaTeksti {
        height: 100%;
        padding-top: 40px;
    }
    
    .taustaTeksti p,
    .tausta2Teksti p {
        width: 700px;
        padding-top: 80px;
        font-size: 125%;
    }
    
    .tausta2 {
        background-color: #002E2D35;
        height: auto;
        padding-bottom: 40px;
    }
    
    .tausta2Kuva {
        text-align: center;
    }
    
    .mika2001 {
        background-color: #FFF;
        padding: 10px;
        margin: auto;
        width: 400px;
        margin-top: 0px;
    }
    
    .tausta2Teksti p {
        padding-top: 140px;
    }
    
    .koulutus {
        background-color: #002E2D50;
        text-align: center;
        padding: 80px;
        border-top: 1px solid #FFF;
    }
    
    .koulutus h4 {
        font-size: 125%;
        text-transform: uppercase;
        color: #002E2D;
        border-bottom: 2px solid #FE9A62;
        width: 250px;
        margin: auto;
        padding: 10px;
        margin-bottom: 20px;
        letter-spacing: 2px;
    }
    
    .koulutus p {
        color: #FFF;
        font-size: 115%;
        letter-spacing: 1px;
    }
    
    .vahvuudet {
        background-color: #002E2D70;
        height: auto;
        padding-left: 60px;
    }
    
    .vahvuudetTeksti {
        padding-top: 140px;
    }
    
    .vahvuudetTeksti h4 {
        font-size: 125%;
        text-transform: uppercase;
        color: #FFF;
        letter-spacing: 2px;
        border-bottom: 2px solid #FE9A62;
        width: 700px;
        padding-bottom: 10px;
        padding-left: 10px;
    }
    
    .vahvuudetTeksti p {
        color: #FFF;
        padding-top: 20px;
        width: 90%;
        font-size: 115%;
    }
    
    .tahtikunto {
        border-top: 1px solid #FE9A62;
    }
    
    .tahtikunto a {
        color: #FE9A62;
        font-size: 120%;
        font-family: 'Style Script', cursive;
    }
    
    .tahtikunto a:hover {
        color: #002E2D90;
    }
    
    .vahvuudetTeksti .haluatko {
        font-family: 'Great Vibes', cursive;
        font-size: 175%;
    }
    
    .vahvuudetKuva {
        background-color: #FFF;
        text-align: center;
        padding: 2% 20px;
    }
    
    .enniMika {
        height: 815px;
        background-color: #FF850190;
        padding: 15px;
    }
    
    .saavutukset {
        background-color: #002E2D80;
        padding: 30px;
    }
    
    .saavutuksetOtsikko {
        text-align: center;
    }
    
    .saavutuksetOtsikko h4 {
        margin: auto;
        font-size: 115%;
        color: #FFF;
        letter-spacing: 1px;
        border-bottom: 2px solid #FE9A62;
        width: 1000px;
        padding-bottom: 10px;
        margin-bottom: 30px;
    }
    
    .saavutuksetTaulukko {
        text-align: right;
    }
    
    .saavutuksetTaulukko div {
        margin: auto;
        width: 500px;
        margin-left: 20%;
        margin-top: 20px;
    }
    
    th {
        font-size: 17px;
        padding-left: 10px;
        width: 70px;
    }
    
    td {
        font-size: 16px;
        letter-spacing: 0.5px;
        text-align: left;
    }
    
    .saavutuksetKuvat {
        padding-top: 20px;
        text-align: left;
    }
    
    .saavutuksetKuva {
        width: 350px;
        padding: 10px;
        background-color: #FFF;
        margin: 20px;
    }
    
    @media screen and (max-width: 1500px) {
        .kuvaus2 {
            font-size: 95%;
        }
        .kuvaus2 p {
            padding: 60px 20px;
        }
        .saavutuksetTaulukko div {
            margin-left: 15%;
        }
    }
    
    @media screen and (max-width: 1400px) {
        .kuvaus p {
            padding-top: 180px;
            padding-left: 75px;
            padding-right: 50px;
            font-size: 130%;
        }
        .vahvuudetTeksti {
            padding-top: 60px;
        }
        .vahvuudetTeksti h4 {
            width: 450px;
        }
    }
    
    @media screen and (max-width: 1250px) {
        .kuvaus p {
            font-size: 120%;
        }
        .koira {
            height: 400px;
        }
        .kuvaus2 p {
            padding: 25px 5px;
        }
        .tausta h4 {
            margin-left: 43%;
        }
        .taustaTeksti p,
        .tausta2Teksti p {
            width: 500px;
        }
        .vahvuudet {
            padding-left: 20px;
        }
        .vahvuudetTeksti {
            padding-top: 20px;
        }
        .vahvuudetTeksti p {
            font-size: 110%;
            width: 95%;
        }
        .haluatko {
            padding-bottom: 20px;
        }
        .enniMika {
            height: 660px;
            padding: 20px;
        }
        .saavutukset {
            padding: 30px 5px;
        }
        .saavutuksetOtsikko h4 {
            width: 90%;
        }
        .saavutuksetTaulukko div {
            margin: auto;
            margin-top: 20px;
            margin-left: 30px;
        }
    }
    
    @media screen and (max-width: 992px) {
        .aboutMika {
            padding: 40px 20px;
        }
        .mikanKuva {
            height: 600px;
            border: 10px solid #FFF;
        }
        .kuvaus p {
            padding-right: 20px;
            padding-top: 70px;
            font-size: 110%;
        }
        .aboutMika2 {
            text-align: center;
        }
        .kuvaus2 {
            height: 275px;
            font-size: 90%;
        }
        .koira {
            margin: auto;
        }
        .tausta h4 {
            text-align: center;
            margin: auto;
            margin-top: 0px;
            height: 40px;
            padding-top: 0px;
        }
        .taustaKuva {
            height: auto;
            padding: 20px;
        }
        .mika1982,
        .mika1994 {
            margin: 0px 30px;
            height: 450px;
            width: auto;
        }
        .taustaTeksti {
            height: auto;
        }
        .taustaTeksti,
        .tausta2Teksti {
            text-align: center;
            padding-top: 0px;
        }
        .taustaTeksti p,
        .tausta2Teksti p {
            width: 700px;
            padding-top: 20px;
            font-size: 125%;
            margin: auto;
        }
        .mika2001 {
            margin: 10px auto;
        }
        .tausta2 {
            padding-bottom: 40px;
        }
        .vahvuudet {
            padding: 20px;
            border-top: 1px solid #FFF;
            border-bottom: 1px solid #FFF;
        }
        .vahvuudetTeksti h4 {
            font-size: 100%;
            width: 90%;
        }
        .vahvuudetTeksti p {
            font-size: 110%;
            width: auto;
        }
        .vahvuudetKuva {
            background-color: #002E2D00;
            margin: auto;
        }
        .enniMika {
            height: 550px;
            padding: 10px;
            background-color: #FFF;
        }
        .saavutuksetTaulukko {
            text-align: center;
        }
        .saavutuksetTaulukko div {
            margin: 20px auto;
        }
        .saavutuksetKuvat {
            padding-top: 10px;
            text-align: center;
        }
        .saavutuksetKuva {
            margin-bottom: 10px;
        }
    }
    
    @media screen and (max-width: 800px) {
        .kuvaus2 {
            height: auto;
        }
        .kuvaus2 p {
            padding: 20px 40px;
        }
        .koira {
            height: auto;
            width: 90%;
        }
        .tausta {
            height: auto;
        }
        .tausta h4 {
            margin: 20px auto 10px;
        }
        .taustaKuva {
            padding: 5px;
        }
        .mika1982,
        .mika1994 {
            margin: 10px 60px;
            width: 325px;
            height: auto;
        }
        .taustaTeksti {
            height: auto;
        }
        .taustaTeksti p,
        .tausta2Teksti p {
            width: 90%;
            font-size: 110%;
        }
        .vahvuudetTeksti {
            text-align: center;
            width: 100%;
            margin: auto;
        }
        .vahvuudetTeksti h4 {
            padding: 0;
            padding-bottom: 10px;
            margin: auto;
        }
        .vahvuudetTeksti p {
            padding: 20px;
            margin: auto;
        }
        .vahvuudetKuva {
            background-color: #002E2D00;
            margin: auto;
        }
    }
    
    @media screen and (max-width: 768px) {
        .aboutMikaKuva {
            padding: 20px;
        }
        .kuvaus p {
            padding: 20px 30px;
        }
        .saavutukset {
            padding: 10px;
        }
        .saavutuksetOtsikko h4 {
            font-size: 90%;
        }
        .saavutuksetTaulukko div {
            width: 90%;
            padding: 0;
            margin: auto;
            margin-top: 20px;
        }
        .saavutuksetKuva {
            margin: 10px auto;
        }
    }
    
    @media screen and (max-width: 700px) {
        .slogan {
            padding: 35px;
        }
        .treenataan {
            width: 90%;
            font-size: 150%;
        }
    }
    
    @media screen and (max-width: 650px) {
        th {
            font-size: 15px;
        }
        td {
            font-size: 14px;
        }
    }
    
    @media screen and (max-width: 600px) {
        .mikanKuva {
            height: auto;
            width: 95%;
        }
        .kuvaus p {
            font-size: 100%;
        }
        .aboutMika2 {
            padding: 10px;
        }
        .slogan {
            height: 150px;
        }
        .treenataan {
            font-size: 125%;
            padding: 20px;
        }
        .tausta h4 {
            width: 80%;
            font-size: 90%;
        }
        .mika1982,
        .mika1994 {
            margin: 10px auto;
        }
        .enniMika {
            height: auto;
            width: 95%;
        }
    }
    
    @media screen and (max-width: 500px) {
        .topRow {
            padding: 10px;
        }
        .topRow h1 {
            font-size: 200%;
            letter-spacing: 1px;
        }
        .sloganit {
            font-size: 120%;
        }
        .aboutMika2 {
            padding: 10px;
        }
        .kuvaus2 {
            font-size: 80%;
            height: auto;
        }
        .kuvaus2 p {
            padding: 10px;
        }
        .slogan {
            padding: 20px;
            height: 145px;
        }
        .koulutus h4 {
            font-size: 115%;
        }
        .koulutus p {
            font-size: 105%;
        }
        .taustaKuva {
            height: auto;
        }
        .taustaTeksti {
            height: auto;
            padding: 0 10px 30px;
        }
        .mika1982,
        .mika1994 {
            width: 90%;
        }
        .mika2001 {
            width: 95%;
        }
        th {
            font-size: 13px;
        }
        td {
            font-size: 12px;
        }
        .saavutuksetKuva {
            margin: 5px auto;
            width: 85%;
        }
    }
.palvelutTop {
    text-align: center;
    background: url(/static/media/dumbbells.e2b38ab1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
}

.palvelutHeader {
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    color: #FFF;
    height: 475px;
    letter-spacing: 3px;
    padding-top: 200px;
}

.palvelutHeader h1 {
    padding: 30px;
    height: 100px;
    text-shadow: 1px 1px 10px #002E2D;
    border-bottom: 0.25px solid #FE9A62;
    font-size: 300%;
}

.palvelutKuvaus {
    height: auto;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    color: #000;
    letter-spacing: 1px;
    padding: 40px 0px 175px 0px;
}

.palvelutKuvaus p {
    width: 100%;
    margin: auto;
    background-color: #FFFFFF99;
    color: #000;
    text-shadow: 0px 0px 5px #FFF;
    font-size: 180%;
    padding-left: 10px;
    padding-right: 10px;
}

.palvelutKuvaus1 {
    padding-bottom: 10px;
    padding-top: 20px;
}

.palvelutKuvaus2 {
    padding-top: 10px;
    padding-bottom: 20px;
}

.erityis {
    height: auto;
    background-color: #FF850120;
    text-align: center;
    color: #002E2D99;
    padding: 220px 40px;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
}

.erityis h4 {
    width: 1000px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 165%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 20px;
    border-bottom: 2px solid #FE9A6280;
    text-shadow: 0px 0px 5px #FFF;
}

.erityis ul {
    padding: 0;
    padding-top: 20px;
}

.erityis li {
    font-size: 150%;
    padding: 20px;
    list-style-type: none;
    color: #002E2D90;
    transition: 0.4s;
}

.erityis li:hover {
    font-size: 170%;
    text-shadow: 0px 0px 5px #FFF;
}

.erityis .erityisAlin {
    border-bottom: none;
}

.benefits {
    height: auto;
    font-family: 'PT Sans Narrow', sans-serif;
    background-color: #002E2D80;
    padding: 130px 40px;
    text-align: center;
}

.benefitsBox {
    color: #002E2D90;
    background-color: #FFFFFF;
    padding: 80px 60px;
    width: 70%;
    margin: auto;
    border-radius: 10px 40px;
    text-align: left;
}

.benefits h4 {
    font-size: 175%;
    color: #002E2D;
    font-weight: lighter;
    font-family: 'PT Sans Narrow', sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-bottom: 2px solid #FE9A6280;
    width: 300px;
    padding-left: 20px;
    padding-bottom: 10px;
    margin-left: 40px;
}

.benefits ul {
    list-style: none;
    padding-left: 0;
    list-style-position: outside;
}

.benefits ul li {
    font-size: 140%;
    padding: 10px 0px;
}

.benefits ul li::before {
    color: #FF8501;
    content: "\2022";
    padding-right: 20px;
    font-size: 135%;
}

.benefits p {
    font-size: 140%;
}

.ohjausKuva {
    background: url(/static/media/blackwhite.4b49a60b.png);
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
    height: 90vh;
    border-top: 2px solid #FFF;
    border-bottom: 2px solid #FFF;
}

.ravinto {
    background-color: #FF850120;
    color: #002E2D99;
    height: auto;
    padding-bottom: 10px;
}

.ravinto h4 {
    text-align: center;
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    padding-top: 40px;
    font-size: 300%;
    letter-spacing: 2px;
    text-shadow: 4px 4px 2px #FFF;
    color: #002E2D99;
}

.kuvaTeksti {
    width: 100%;
    padding: 20px;
}

.kuvaTeksti .kuvaTekstiDiv {
    width: 60%;
    float: left;
    padding: 80px 100px 0px 100px;
}

.ravinto .ruokakuva {
    width: 40%;
    float: left;
    padding: 30px;
    background-color: #FFF;
    border-radius: 10px;
}

.ravinto .ravintoTeksti {
    font-size: 170%;
    font-family: 'PT Sans Narrow', sans-serif;
}

.ravinto .olet {
    font-family: 'Great Vibes', cursive;
    font-size: 300%;
    color: #FF8501;
    text-align: center;
    padding-top: 20px;
    margin-left: -40px;
}

.kuvaTeksti:after {
    content: "";
    display: table;
    clear: both;
}

.kilpailu {
    background-color: #002E2D80;
    height: auto;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
}

.kilpailu h4 {
    font-family: 'Style Script', cursive;
    color: #FFF;
    letter-spacing: 2px;
    font-size: 350%;
    border-bottom: 1px solid #FE9A62;
    font-weight: lighter;
    text-align: center;
    padding-top: 60px;
    width: 335px;
    margin: auto;
    line-height: 80%;
}

.kilpailuDiv {
    width: 100%;
    padding: 30px 30px 40px 30px;
}

.kilpailuDiv img {
    width: 25%;
    float: left;
    padding: 10px;
    background-color: #FFF;
}

.kilpailuDiv p {
    width: 75%;
    float: left;
    font-family: 'PT Sans Narrow', sans-serif;
    color: #FFF;
    font-size: 175%;
    padding: 175px 40px 0 40px;
}

.nimiLinkki {
    font-family: 'Style Script', cursive;
    color: #FE9A62;
    font-size: 120%;
}

.nimiLinkki:hover {
    color: #002E2D;
}

.kilpailuDiv:after {
    content: "";
    display: table;
    clear: both;
}

.hinnat {
    text-align: center;
    background-color: #FF850120;
    color: #002E2D99;
    height: auto;
    font-family: 'PT Sans Narrow', sans-serif;
    padding: 100px;
}

.hinnat h4 {
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 300%;
    text-transform: uppercase;
    padding-bottom: 30px;
}

.hinnat .hinnatKuvaus {
    font-size: 200%;
    padding-left: 20px;
    padding-right: 20px;
}

.hinnat .hinnatSis {
    color: #002E2D;
    border-bottom: 1px solid #FE9A6280;
    width: 325px;
    margin: auto;
    transition: 0.3s;
    font-size: 200%;
}

.hinnat p {
    padding: 5px;
    margin: 0;
}

.hinnatDiv {
    background-color: #FFFFFF90;
    margin: auto;
    width: 750px;
    padding: 30px;
    border-radius: 40px;
    margin-top: 20px;
    transition: 0.3s;
}

.hinnatDiv:hover .hinnatSis {
    width: 400px;
}

.hinnat ul {
    list-style: none;
    padding-top: 30px;
    color: #002E2D;
    padding-bottom: 20px;
}

.hinnat ul li {
    margin-left: -30px;
    font-size: 150%;
    padding: 10px;
}

.mayors {
    color: #002E2D99;
    text-transform: uppercase;
}

.mayors:hover {
    color: #FF8501;
}

.hinnat .alkaen {
    text-transform: uppercase;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    margin-left: 0px;
    font-size: 200%;
}

.linkkiDiv {
    margin-top: 60px;
    margin-bottom: 40px;
}

.yhteydenottoLinkki {
    color: #FFF;
    background-color: #FF850199;
    width: 200px;
    margin: auto;
    text-transform: uppercase;
    padding: 25px;
    font-size: 175%;
    border-radius: 20px;
    border: 1px solid #FFF;
}

.yhteydenottoLinkki:hover {
    background-color: #FFF;
    color: #FF850199;
    border: 1px solid #FF850199;
}

.hieronta {
    background-color: #FF850190;
    color: #FFF;
    padding: 60px 80px;
    font-family: 'PT Sans Narrow', sans-serif;
    border-top: 1px solid #FFF;
}

.hieronta h4 {
    font-family: 'PT Sans Narrow', sans-serif;
    text-transform: uppercase;
    font-size: 250%;
    border-bottom: 2px solid #FF8501;
    width: 275px;
}

.hieronta p {
    color: #FFF;
    font-size: 200%;
}

.hieronta p a {
    font-family: 'Style Script', cursive;
    font-size: 110%;
    color: #002E2D;
    text-shadow: 1px 1px 10px #FFF;
}

.hieronta p a:hover {
    color: #FFFFFF;
    text-shadow: 1px 1px 10px #002E2D;
}

@media screen and (max-width: 1500px) {
    .kuvaTeksti .kuvaTekstiDiv {
        width: 50%;
        padding: 50px 50px 0px 50px;
    }
    .ravinto .ravintoTeksti {
        font-size: 150%;
    }
    .ravinto .ruokakuva {
        width: 50%;
    }
    .kilpailuDiv img {
        width: 35%;
    }
    .kilpailuDiv p {
        width: 65%;
    }
}

@media screen and (max-width: 1200px) {
    .kuvaTeksti {
        padding: 20px 50px;
    }
    .kuvaTeksti .kuvaTekstiDiv {
        width: 100%;
    }
    .ravinto .ruokakuva {
        margin-left: 5%;
        width: 90%;
        margin-top: 40px;
    }
    .ravinto .olet {
        margin-left: 0px;
    }
    .kilpailuDiv p {
        padding: 125px 40px 0 40px;
    }
}

@media screen and (max-width: 1100px) {
    .erityis h4 {
        width: 90%;
        font-size: 150%;
    }
    .benefitsBox {
        width: 90%;
    }
    .kilpailuDiv p {
        padding: 100px 20px 0 20px;
        font-size: 150%;
    }
    .hinnat {
        padding: 100px 20px;
    }
    .hinnatDiv {
        width: 70%;
    }
}

@media screen and (max-width: 900px) {
    .palvelutKuvaus p {
        font-size: 150%;
    }
    .erityis {
        padding: 120px 40px;
    }
    .erityis h4 {
        font-size: 130%;
    }
    .erityis li {
        font-size: 125%;
        padding: 20px 5px;
    }
    .erityis li:hover {
        font-size: 125%;
    }
    .benefits {
        padding: 100px 0px;
    }
    .benefits ul li,
    .benefits p {
        font-size: 125%;
    }
    .benefitsBox {
        padding: 80px 30px;
    }
    .ohjausKuva {
        height: 60vh;
    }
    .kuvaTeksti .kuvaTekstiDiv {
        padding: 20px;
        text-align: center;
    }
    .ravinto .ruokakuva {
        padding: 20px;
    }
    .kilpailuDiv {
        padding: 30px 20px 40px 20px;
    }
    .kilpailuDiv img,
    .kilpailuDiv p {
        width: 50%;
    }
    .kilpailuDiv p {
        padding-right: 0;
    }
    .hinnatDiv {
        width: 80%;
    }
}

@media screen and (max-width: 750px) {
    .kilpailuDiv {
        padding: 60px 20px 40px 20px;
    }
    .kilpailuDiv img {
        width: 90%;
        margin-left: 5%;
    }
    .kilpailuDiv p {
        text-align: center;
        width: 100%;
        padding: 80px 10px;
    }
    .hinnat {
        padding: 80px 10px;
    }
    .hinnatDiv {
        width: 90%;
    }
    .hinnat .hinnatKuvaus,
    .hinnat .hinnatSis,
    .hinnat .alkaen {
        font-size: 150%;
    }
    .hinnat ul li {
        font-size: 125%;
    }
    .hinnat .hinnatSis {
        width: 80%;
    }
    .hinnatDiv:hover .hinnatSis {
        width: 90%;
    }
    .hieronta {
        padding: 60px 10%;
    }
    .hieronta h4 {
        font-family: 'PT Sans Narrow', sans-serif;
        text-transform: uppercase;
        font-size: 200%;
        border-bottom: 2px solid #FF8501;
        width: 225px;
    }
    .hieronta p {
        color: #FFF;
        font-size: 150%;
    }
    .hieronta p a {
        font-family: 'Style Script', cursive;
        font-size: 110%;
        color: #002E2D;
        text-shadow: 1px 1px 10px #FFF;
    }
}

@media screen and (max-width: 600px) {
    .palvelutHeader h1 {
        font-size: 250%;
    }
    .palvelutKuvaus p {
        font-size: 125%;
    }
    .erityis {
        padding: 80px 10px;
    }
    .benefits h4 {
        font-size: 160%;
        width: 90%;
        padding-left: 20px;
        margin: auto;
    }
    .ravinto h4 {
        font-size: 250%;
    }
    .kuvaTeksti {
        padding: 10px;
    }
    .ohjausKuva {
        height: 40vh;
    }
    .ravinto .ruokakuva {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
    .kilpailu h4 {
        font-size: 300%;
    }
    .kilpailuDiv {
        padding: 40px 20px 40px 20px;
    }
    .kilpailuDiv p {
        padding: 40px 10px;
    }
    .yhteydenottoLinkki {
        font-size: 150%;
    }
}
body {
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
}

.tiimiPage {
    font-family: 'PT Sans Narrow', sans-serif;
}

.headerColumn {
    text-align: center;
    font-family: 'PT Sans Narrow', sans-serif;
    background-color: #FE9A6280;
    height: auto;
}

.headerColumn h1 {
    text-transform: uppercase;
    color: #002E2D95;
    letter-spacing: 5px;
    padding: 50px 0px;
    width: 90%;
    margin: auto;
    background-color: #FFF;
    border-radius: 0px 0px 40px 40px;
}

.headerColumn img {
    width: 900px;
    background-color: #FFF;
    padding: 10px;
    margin: 20px;
}

.headerColumn p {
    font-size: 170%;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #002E2D;
    margin: 0;
}

.headerColumn p .haluatko {
    font-family: 'Style Script', cursive;
    font-size: 200%;
    transition: 0.2s;
    color: #002E2D;
}

.headerColumn .haluatko:hover {
    color: #FF8501;
    text-shadow: 3px 3px 5px #FFF;
}

.tiimi,
.tiimi2 {
    height: auto;
    text-align: center;
    padding: 30px;
    color: #002E2D;
    border-top: 2px solid #FFF;
}

.tiimi {
    background-color: #FF850120;
}

.tiimi2 {
    background-color: #FE9A6250;
}

.tiimi h4,
.tiimi2 h4 {
    font-size: 300%;
    text-transform: uppercase;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    letter-spacing: 2px;
    font-weight: lighter;
    margin-bottom: 0;
}

.tiimi h5,
.tiimi2 h5 {
    font-family: 'Style Script', cursive;
    font-size: 150%;
    letter-spacing: 1px;
    font-weight: lighter;
    margin-top: 0;
    padding-bottom: 20px;
}

.tiimiKuva {
    height: 500px;
    width: auto;
    border: 3px solid #FFF;
    border-radius: 3px;
}

.kuvaYksi {
    margin-right: 20px;
}

.kuvaKaksi {
    margin-left: 20px;
}

.teksti p {
    color: #002E2D99;
    padding: 30px 150px;
    font-size: 125%;
}

.nea .teksti p {
    padding: 30px 100px;
}

@media screen and (max-width: 1100px) {
    .headerColumn p {
        padding-left: 100px;
        padding-right: 100px;
    }
    .tiimi,
    .tiimi2 {
        height: auto;
    }
    .henna {
        padding: 30px 5px;
    }
    .henna .kuvaYksi {
        margin-right: 5px;
    }
    .henna .kuvaKaksi {
        margin-left: 5px;
    }
    .teksti p {
        padding: 10px 100px;
    }
}

@media screen and (max-width: 992px) {
    .headerColumn img {
        width: 90%;
    }
    .tiimiKuva {
        height: auto;
        width: 400px;
        border: 3px solid #FFF;
        border-radius: 3px;
    }
    .henna {
        padding: 30px;
    }
    .henna .kuvaYksi,
    .henna .kuvaKaksi {
        margin: 10px 50px;
    }
    .kuvaYksi,
    .kuvaKaksi {
        margin: 10px 50px;
    }
    .teksti p,
    .nea .teksti p {
        padding: 10px 50px;
    }
}

@media screen and (max-width: 768px) {
    .headerColumn img {
        width: 95%;
    }
    .headerColumn p {
        padding-left: 50px;
        padding-right: 50px;
    }
    .headerColumn p .haluatko {
        font-size: 150%;
    }
    .teksti p,
    .nea .teksti p {
        padding: 10px;
    }
}

@media screen and (max-width: 576px) {
    .headerColumn h1 {
        font-size: 150%;
    }
    .headerColumn p {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 140%;
    }
    .tiimiKuva {
        width: 90%;
    }
    .kuvaYksi,
    .kuvaKaksi,
    .henna .kuvaYksi,
    .henna .kuvaKaksi {
        margin: 10px auto;
    }
}
body {
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
}

.yhteydenotto {
    height: auto;
    padding-bottom: 50px;
    background-color: #002E2D10;
    text-align: center;
    font-family: 'PT Sans Narrow', sans-serif;
}

.otayhteytta {
    text-align: center;
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    padding: 40px;
    font-size: 250%;
    color: #002E2D90;
    width: 90%;
    border-bottom: 1px solid white;
    margin: auto;
}

.yhteystiedot {
    width: 350px;
    margin: auto;
    margin-top: 40px;
    border-bottom: 1px solid white;
    padding-bottom: 20px;
}

.yhteystiedot a,
.yhteystiedot p {
    color: #002E2D95;
    font-size: 150%;
    padding: 10px;
}

.yhteystiedot a:hover {
    color: #FE9A62;
}

.some {
    width: 140px;
    margin: 10px auto;
    text-align: center;
}

.ig,
.fb {
    width: 40px;
    margin: 20px 10px;
}

.instagram,
.facebook {
    float: left;
    width: 50%;
}

.some:after {
    content: "";
    display: table;
    clear: both;
}

.yhteydenottoLomake {
    text-align: center;
    max-width: 400px;
    margin: 20px auto;
}

.lomake {}

Form {
    border: 2px solid #FE9A6250;
    border-radius: 10px;
    padding: 30px;
    background-color: #002E2D05;
}

.lomake label {
    text-align: left;
    padding-left: 40px;
    padding-top: 10px;
}
