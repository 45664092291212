@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
body {
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
}

.galleria {
    padding: 0px;
    padding-bottom: 20px;
    width: 100%;
}

.galleria h2 {
    font-family: 'PT Sans Narrow', sans-serif;
    color: #FFF;
    background-color: #002E2D90;
    margin: 0;
    padding: 40px;
    font-size: 225%;
    font-weight: lighter;
}

.galleria h2 a {
    font-family: 'Style Script', cursive;
    color: #FE9A62;
    font-size: 125%;
    transition: 0.4s;
}

.galleria h2 a:hover {
    color: #FFF;
    text-shadow: 2px 2px 5px #FE9A62;
    padding: 5px;
}

.galleria h3 {
    text-align: center;
    border-top: 1px solid #002E2D90;
    color: #002E2D90;
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    padding-top: 20px;
    margin-top: 0;
    font-size: 200%;
    letter-spacing: 1px;
}

@media screen and (max-width: 900px) {
    .galleria h2 {
        text-align: center;
        font-size: 200%;
    }
    .galleria h3 {
        font-size: 175%;
    }
}