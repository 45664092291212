@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&display=swap');
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

body,
html {
    height: 100%;
}


/* https://codepen.io/codebucks27/pen/yLMvOPX*/

body {
    position: relative;
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
    width: 100%;
    border-top: 20px solid #FF8501;
}

.header {
    height: calc(90vh - 80px);
    border: 3px solid #FFF;
}

.header img {
    max-height: 90%;
    max-width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4%;
}

#nav {
    border-top: 10px solid #FFF;
    border-bottom: 1px solid #FE9A62;
    height: 80px;
    height: calc(100% - 10px);
    position: sticky;
    position: -webkit-sticky;
    top: 0;
}

.navigation {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #FFF;
    text-transform: uppercase;
    font-family: 'PT Sans Narrow', sans-serif;
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1500px;
    height: 50px;
}

.main-container {
    /*position: absolute;*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.nav-menu {
    display: flex;
    text-align: center;
    list-style: none;
}

.linkTo {
    text-decoration: none;
    color: #002E2D80;
    padding-right: 40px;
    padding: 0px 30px;
    /*border-right: 1px solid #002E2D80;*/
    font-size: 125%;
    height: 100%;
}

.links {
    display: inline-block;
    padding-top: 15px;
    padding-bottom: 15px;
    float: none;
    line-height: 50px;
}

.links::after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.links:hover::after {
    width: 100%;
    background-color: #FE9A62;
}

.nav-icon {
    display: none;
}

#yhteydenotto {
    border-right: none;
}

.linkTo:hover {
    color: #FE9A62;
}

.kuvat {
    width: 50%;
}

@media screen and (max-width: 960px) {
    .header {
        height: calc(60% - 80px);
    }
    .header img {
        width: 72.5%;
    }
    #nav {
        border-top: 0;
        height: 50px;
        z-index: 1;
        /* position: sticky;*/
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #FE9A62;
        position: absolute;
        top: 50px;
        left: -110%;
        transition: all 0.5s ease;
        height: 500px;
        background-color: #FFF;
        padding-left: 0;
    }
    .nav-menu.active {
        left: 0px;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .links .active {
        color: #FE9A62;
        border: none;
        display: table;
    }
    .navigation {
        display: table;
        margin-right: 20px;
        border-bottom: 1px solid #FE9A62;
    }
    .linkTo {
        width: 100%;
        display: table;
        border-right: none;
    }
    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #FF8501;
    }
}