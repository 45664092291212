@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
body {
    font-family: 'PT Sans Narrow', sans-serif;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    font-family: 'Open Sans', sans-serif;
    color: #FFF;
    color: #FE9A62;
    color: #FF8501;
    color: #B05600;
    color: #002E2D;
    color: #000;
}

.tiimiPage {
    font-family: 'PT Sans Narrow', sans-serif;
}

.headerColumn {
    text-align: center;
    font-family: 'PT Sans Narrow', sans-serif;
    background-color: #FE9A6280;
    height: auto;
}

.headerColumn h1 {
    text-transform: uppercase;
    color: #002E2D95;
    letter-spacing: 5px;
    padding: 50px 0px;
    width: 90%;
    margin: auto;
    background-color: #FFF;
    border-radius: 0px 0px 40px 40px;
}

.headerColumn img {
    width: 900px;
    background-color: #FFF;
    padding: 10px;
    margin: 20px;
}

.headerColumn p {
    font-size: 170%;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #002E2D;
    margin: 0;
}

.headerColumn p .haluatko {
    font-family: 'Style Script', cursive;
    font-size: 200%;
    transition: 0.2s;
    color: #002E2D;
}

.headerColumn .haluatko:hover {
    color: #FF8501;
    text-shadow: 3px 3px 5px #FFF;
}

.tiimi,
.tiimi2 {
    height: auto;
    text-align: center;
    padding: 30px;
    color: #002E2D;
    border-top: 2px solid #FFF;
}

.tiimi {
    background-color: #FF850120;
}

.tiimi2 {
    background-color: #FE9A6250;
}

.tiimi h4,
.tiimi2 h4 {
    font-size: 300%;
    text-transform: uppercase;
    font-family: 'Yanone Kaffeesatz', sans-serif;
    letter-spacing: 2px;
    font-weight: lighter;
    margin-bottom: 0;
}

.tiimi h5,
.tiimi2 h5 {
    font-family: 'Style Script', cursive;
    font-size: 150%;
    letter-spacing: 1px;
    font-weight: lighter;
    margin-top: 0;
    padding-bottom: 20px;
}

.tiimiKuva {
    height: 500px;
    width: auto;
    border: 3px solid #FFF;
    border-radius: 3px;
}

.kuvaYksi {
    margin-right: 20px;
}

.kuvaKaksi {
    margin-left: 20px;
}

.teksti p {
    color: #002E2D99;
    padding: 30px 150px;
    font-size: 125%;
}

.nea .teksti p {
    padding: 30px 100px;
}

@media screen and (max-width: 1100px) {
    .headerColumn p {
        padding-left: 100px;
        padding-right: 100px;
    }
    .tiimi,
    .tiimi2 {
        height: auto;
    }
    .henna {
        padding: 30px 5px;
    }
    .henna .kuvaYksi {
        margin-right: 5px;
    }
    .henna .kuvaKaksi {
        margin-left: 5px;
    }
    .teksti p {
        padding: 10px 100px;
    }
}

@media screen and (max-width: 992px) {
    .headerColumn img {
        width: 90%;
    }
    .tiimiKuva {
        height: auto;
        width: 400px;
        border: 3px solid #FFF;
        border-radius: 3px;
    }
    .henna {
        padding: 30px;
    }
    .henna .kuvaYksi,
    .henna .kuvaKaksi {
        margin: 10px 50px;
    }
    .kuvaYksi,
    .kuvaKaksi {
        margin: 10px 50px;
    }
    .teksti p,
    .nea .teksti p {
        padding: 10px 50px;
    }
}

@media screen and (max-width: 768px) {
    .headerColumn img {
        width: 95%;
    }
    .headerColumn p {
        padding-left: 50px;
        padding-right: 50px;
    }
    .headerColumn p .haluatko {
        font-size: 150%;
    }
    .teksti p,
    .nea .teksti p {
        padding: 10px;
    }
}

@media screen and (max-width: 576px) {
    .headerColumn h1 {
        font-size: 150%;
    }
    .headerColumn p {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 140%;
    }
    .tiimiKuva {
        width: 90%;
    }
    .kuvaYksi,
    .kuvaKaksi,
    .henna .kuvaYksi,
    .henna .kuvaKaksi {
        margin: 10px auto;
    }
}